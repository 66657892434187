exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-ambasadorzy-formularz-tsx": () => import("./../../../src/pages/ambasadorzy/formularz.tsx" /* webpackChunkName: "component---src-pages-ambasadorzy-formularz-tsx" */),
  "component---src-pages-ambasadorzy-index-tsx": () => import("./../../../src/pages/ambasadorzy/index.tsx" /* webpackChunkName: "component---src-pages-ambasadorzy-index-tsx" */),
  "component---src-pages-ambasadorzy-lista-ambasadorow-tsx": () => import("./../../../src/pages/ambasadorzy/lista-ambasadorow.tsx" /* webpackChunkName: "component---src-pages-ambasadorzy-lista-ambasadorow-tsx" */),
  "component---src-pages-blog-index-tsx": () => import("./../../../src/pages/blog/index.tsx" /* webpackChunkName: "component---src-pages-blog-index-tsx" */),
  "component---src-pages-budynek-pasywny-historia-tsx": () => import("./../../../src/pages/budynek-pasywny/historia.tsx" /* webpackChunkName: "component---src-pages-budynek-pasywny-historia-tsx" */),
  "component---src-pages-budynek-pasywny-index-tsx": () => import("./../../../src/pages/budynek-pasywny/index.tsx" /* webpackChunkName: "component---src-pages-budynek-pasywny-index-tsx" */),
  "component---src-pages-budynek-pasywny-wymagania-tsx": () => import("./../../../src/pages/budynek-pasywny/wymagania.tsx" /* webpackChunkName: "component---src-pages-budynek-pasywny-wymagania-tsx" */),
  "component---src-pages-certyfikacja-budynkow-tsx": () => import("./../../../src/pages/certyfikacja/budynkow.tsx" /* webpackChunkName: "component---src-pages-certyfikacja-budynkow-tsx" */),
  "component---src-pages-certyfikacja-index-tsx": () => import("./../../../src/pages/certyfikacja/index.tsx" /* webpackChunkName: "component---src-pages-certyfikacja-index-tsx" */),
  "component---src-pages-certyfikacja-komponentow-tsx": () => import("./../../../src/pages/certyfikacja/komponentow.tsx" /* webpackChunkName: "component---src-pages-certyfikacja-komponentow-tsx" */),
  "component---src-pages-certyfikacja-wyszukiwarka-budynkow-tsx": () => import("./../../../src/pages/certyfikacja/wyszukiwarka-budynkow.tsx" /* webpackChunkName: "component---src-pages-certyfikacja-wyszukiwarka-budynkow-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-instytut-index-tsx": () => import("./../../../src/pages/instytut/index.tsx" /* webpackChunkName: "component---src-pages-instytut-index-tsx" */),
  "component---src-pages-instytut-kontakt-tsx": () => import("./../../../src/pages/instytut/kontakt.tsx" /* webpackChunkName: "component---src-pages-instytut-kontakt-tsx" */),
  "component---src-pages-instytut-partnerzy-tsx": () => import("./../../../src/pages/instytut/partnerzy.tsx" /* webpackChunkName: "component---src-pages-instytut-partnerzy-tsx" */),
  "component---src-pages-instytut-zespol-tsx": () => import("./../../../src/pages/instytut/zespol.tsx" /* webpackChunkName: "component---src-pages-instytut-zespol-tsx" */),
  "component---src-pages-sklep-index-tsx": () => import("./../../../src/pages/sklep/index.tsx" /* webpackChunkName: "component---src-pages-sklep-index-tsx" */),
  "component---src-pages-szkolenia-eksperci-tsx": () => import("./../../../src/pages/szkolenia/eksperci.tsx" /* webpackChunkName: "component---src-pages-szkolenia-eksperci-tsx" */),
  "component---src-pages-szkolenia-index-tsx": () => import("./../../../src/pages/szkolenia/index.tsx" /* webpackChunkName: "component---src-pages-szkolenia-index-tsx" */),
  "component---src-pages-szkolenia-mistrz-wykonawca-tsx": () => import("./../../../src/pages/szkolenia/mistrz-wykonawca.tsx" /* webpackChunkName: "component---src-pages-szkolenia-mistrz-wykonawca-tsx" */),
  "component---src-pages-szkolenia-mostki-cieplne-tsx": () => import("./../../../src/pages/szkolenia/mostki-cieplne.tsx" /* webpackChunkName: "component---src-pages-szkolenia-mostki-cieplne-tsx" */),
  "component---src-pages-szkolenia-projektant-doradztwa-tsx": () => import("./../../../src/pages/szkolenia/projektant-doradztwa.tsx" /* webpackChunkName: "component---src-pages-szkolenia-projektant-doradztwa-tsx" */),
  "component---src-templates-blog-template-tsx": () => import("./../../../src/templates/blog-template.tsx" /* webpackChunkName: "component---src-templates-blog-template-tsx" */),
  "component---src-templates-news-template-tsx": () => import("./../../../src/templates/news-template.tsx" /* webpackChunkName: "component---src-templates-news-template-tsx" */)
}

